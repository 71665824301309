<template>
  <fieldset class="form-group border p-3">
    <legend class="w-auto px-2">{{ title }}</legend>
    <table
      class="table table-striped table-dark"
      v-if="certificate.endorsement"
    >
      <thead>
        <tr>
          <th>No</th>
          <th>Alert By System</th>
          <th>Process Date</th>
          <th>Notes</th>
          <th>File</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="certificate.endorsement.length > 0">
          <tr
            v-for="(endorsement, index_endorsement) in certificate.endorsement"
            :key="`end-${index_endorsement}`"
          >
            <td>{{ endorsement.endorseNumber }}</td>
            <td v-if="endorsement.startEndorseDate">{{ endorsement.startEndorseDate }} s/d {{ endorsement.endEndorseDate }}</td>
            <td v-else>{{ endorsement.endEndorseDate }}</td>
            <td>{{
              endorsement.processDate ? endorsement.processDate : '-'
            }}</td>
            <td>{{ endorsement.notes ? endorsement.notes : '-' }}</td>
            <td>
              <template v-if="endorsement.file">
                <a :href="endorsement.file.url" target="_blank"
                  v-b-tooltip.hover
                  :title="certificate.file.fileName">
                  <i class="fa fa-download"></i>
                </a>
              </template>
              <span v-else>-</span>
            </td>
          </tr>
        </template>
        <tr v-else>
          <td colspan="5" class="text-center py-4"
            >No endorsement data found.</td
          >
        </tr>
      </tbody>
    </table>
  </fieldset>
</template>

<script>
export default {
  name: 'EndorsementCertificate',
  props: {
    title: {
      type: String,
      default: 'Endorsement:',
    },
    certificate: {
      type: Object,
      default: {},
    },
  },
}
</script>
