var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2"
  }, [_vm._v(_vm._s(_vm.title))]), _vm.certificate.endorsement ? _c('table', {
    staticClass: "table table-striped table-dark"
  }, [_vm._m(0), _c('tbody', [_vm.certificate.endorsement.length > 0 ? _vm._l(_vm.certificate.endorsement, function (endorsement, index_endorsement) {
    return _c('tr', {
      key: "end-".concat(index_endorsement)
    }, [_c('td', [_vm._v(_vm._s(endorsement.endorseNumber))]), endorsement.startEndorseDate ? _c('td', [_vm._v(_vm._s(endorsement.startEndorseDate) + " s/d " + _vm._s(endorsement.endEndorseDate))]) : _c('td', [_vm._v(_vm._s(endorsement.endEndorseDate))]), _c('td', [_vm._v(_vm._s(endorsement.processDate ? endorsement.processDate : '-'))]), _c('td', [_vm._v(_vm._s(endorsement.notes ? endorsement.notes : '-'))]), _c('td', [endorsement.file ? [_c('a', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      attrs: {
        "href": endorsement.file.url,
        "target": "_blank",
        "title": _vm.certificate.file.fileName
      }
    }, [_c('i', {
      staticClass: "fa fa-download"
    })])] : _c('span', [_vm._v("-")])], 2)]);
  }) : _c('tr', [_c('td', {
    staticClass: "text-center py-4",
    attrs: {
      "colspan": "5"
    }
  }, [_vm._v("No endorsement data found.")])])], 2)]) : _vm._e()]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Alert By System")]), _c('th', [_vm._v("Process Date")]), _c('th', [_vm._v("Notes")]), _c('th', [_vm._v("File")])])]);

}]

export { render, staticRenderFns }